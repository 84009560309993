<template>
  <main
    v-loading="sendMsgLoading"
    class="c--detail-chat"
  >
    <!--    聊天内容-->
    <section
      ref="record-wrap"
      class="record-wrap"
    >
      <template v-for="item in ticketChatRecords">

        <!--        系统消息-->
        <template v-if="item.talkerType === 3">
          <div
            :key="item.id"
            class="system-msg-item"
          >
            <div v-html="formatSystemMsg(item)" />
          </div>
        </template>

        <!--        聊天消息-->
        <template v-else>
          <div
            :key="item.id"
            :class="{ 'is-right': item.talkerType === 1, 'no-bg': item.contentType !== CONTENT_TYPE.TEXT }"
            class="chat-msg-item"
          >
            <div class="avatar">
              <ez-icon
                style="font-size: 38px"
                :icon="item.talkerType === 1 ? 'oms__icon_kehu' : 'oms__icon_kefu'"
                type="svg"
              />
            </div>
            <div class="content-container">
              <div
                v-if="item.createTime"
                class="message-time"
              >
                {{ item.userName }}
                {{ item.createTime }}
              </div>
              <div class="message-wrap">
                <div class="msg-status">
                  <i
                    v-if="item.isError"
                    class="ez-icon-warning"
                  />
                  <i
                    v-if="item.isLoading"
                    class="ez-icon-loading"
                  />
                </div>
                <div v-if="item.contentType === CONTENT_TYPE.TEXT">
                  <div class="text-wrap">
                    {{ removeNbsp(item.content) }}
                  </div>
                </div>
                <div v-else-if="item.contentType === CONTENT_TYPE.IMG">
                  <ez-image
                    fit="contain"
                    style="width: 100px; height: 100px"
                    :src="item.fileUrl"
                    :preview-src-list="[item.fileUrl]"
                  />
                </div>
                <div v-else>
                  <FileName :file-url="$utils.getFileUrl(item.fileUrl, item.fileName)" :file-name="item.fileName" />
                </div>
              </div>
            </div>
          </div>
        </template>

      </template>
    </section>

    <!--    closed工单评价模块-->
    <transition name="ez-fade-in-linear">
      <section class="rate-wrap" v-if="isCanRate" v-show="rateVisible">
        <div class="rate-row">
          {{ $t('common.rate.solveDesc') }}
          <ez-radio-group v-model="rateInfo.scoreResolved" :disabled="rateSubmitted || isLoading" @change="handleRateChange">
            <ez-radio :label="true">
              <i class="oms__iconfont oms__icon_thumbs-up"></i>
              <span>{{ $t('common.rate.yes') }}</span>
            </ez-radio>
            <ez-radio :label="false">
              <i class="oms__iconfont oms__icon_thumbs-down"></i>
              <span>{{ $t('common.rate.no') }}</span>
            </ez-radio>
          </ez-radio-group>
        </div>
        <div>
          {{ $t('common.rate.starDesc') }}
        </div>
        <EzRateStar style="margin-top:9px;margin-bottom:3px;" v-model="rateInfo.scoreLevel" :disabled="rateSubmitted || isLoading" @change="handleRateChange"></EzRateStar>
        <div class="rate-row--submitted" v-show="rateSubmitted">{{ $t('common.rate.submitted') }}</div>
      </section>
    </transition>

    <!--    底部输入框-->
    <section class="send" v-if="isCanChat">
      <div class="input-wrap">
        <ez-input
          v-model="textMsg"
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 6 }"
          size="large"
          resize="none"
          :placeholder="$t('common.addToCon')"
          @keyup.enter.exact.native="handleSendText(true)"
          @blur="handleBlur"
          @focus="handleFocus"
        />
      </div>
      <div class="send-btn">
        <QiniuUpload
          class="attach-icon"
          :max-num="9"
          :max-size="50"
          @beforeUpload="beforeUpload"
        />
        <ez-button
          :disabled="!isEnableSendBtn"
          type="primary"
          @click="handleSendText"
        >
          {{ $t('common.reply') }}
        </ez-button>
      </div>
    </section>
    <PasteUpload
      :disabled="!canPasteUpload"
      @beforeUpload="beforeUpload"
    />
  </main>
</template>

<script>
import QiniuUpload from '../components/QiniuUpload'
import PasteUpload from '../components/PasteUpload'
import mixin from '../mixin'
import { SYSTEM_MSG_TEMPLATE, TICKET_STATUS } from '@/config'
import EzRateStar from '../components/EzRateStar'

const CONTENT_TYPE = {
  TEXT: 1,
  IMG: 2,
  OTHER: 3
}
export default {
  components: {
    QiniuUpload,
    PasteUpload,
    EzRateStar
  },
  mixins: [mixin],
  props: {
    ticketDetail: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      CONTENT_TYPE,
      ticketChatRecords: [],
      sendMsgLoading: false,
      textMsg: null,
      isLoading: false,
      rateVisible: true,
      rateSubmitted: false,
      rateInfo: {
        scoreResolved: null,
        scoreLevel: 0
      },
      canPasteUpload: false
    }
  },
  mounted () {
    this.getChatRecords()
  },
  computed: {
    isCanChat () {
      const status = this.ticketDetail.clientStatus
      const canNotChatStatus = [TICKET_STATUS.CLOSED.VALUE, TICKET_STATUS.WITHDRAWN.VALUE]
      return !canNotChatStatus.includes(status)
    },
    isEnableSendBtn () {
      return !!(this.textMsg || '').trim()
    },
    // closed状态且没有评价过
    isCanRate () {
      const { clientStatus, scoreResolved } = this.ticketDetail
      return clientStatus === TICKET_STATUS.CLOSED.VALUE && scoreResolved === null
    }
  },
  methods: {
    removeNbsp (str) { // 历史数据兼容
      return str.replace(/&nbsp;/g, ' ').replace(/<br\/>/g, '\n')
    },
    formatSystemMsg (item) {
      let { userA, userB, activityType, ticketSubmitId } = JSON.parse(item.content)
      let { language } = this.$utils.getUserInfo()
      const time = item.createTime

      let you = language === 'en' ? 'You' : '您'
      let customer = language === 'en' ? 'Customer Service' : '客服'
      if (['RE_OPEN', 'BORN'].includes(activityType)) {
        userA = ticketSubmitId === item.talkerId ? you : customer
      }
      let template = SYSTEM_MSG_TEMPLATE[activityType] || {}
      template = template[this.$store.state.userInfo.language]
      if (template) {
        return template.replace('USERA', userA).replace('USERB', userB).replace('TIME', time)
      } else {
        return ''
      }
    },
    // 获取中间部分聊天记录
    getChatRecords () {
      const params = {
        ticketCaseId: this.$route.params.ticketCaseId,
        curPageNo: 1,
        pageSize: 10000000
      }
      this.$axios.get('/api/shipout-workorder/ticketcase/listComments', { params })
        .then(res => {
          let _ticketChatRecords = res.data.reverse()
          this.ticketChatRecords = _ticketChatRecords.map(item => ({
            ...item,
            createTime: this.$utils.toLocalTime(item.createTime)
          }))
          this.mixinScrollTo(this.$refs['record-wrap'])
        })
        .catch(error => {
          console.error(error)
        })
    },

    // 工单评价
    handleRateChange () {
      const { scoreResolved, scoreLevel } = this.rateInfo
      if (scoreResolved === null || !scoreLevel || this.isLoading) return

      this.isLoading = true
      this.$axios.post('/api/shipout-workorder/ticketcase/submitClientScore', {
        ticketCaseId: this.ticketDetail.ticketCaseId,
        scoreResolved,
        scoreLevel
      }).then(res => {
        this.isLoading = false
        this.rateSubmitted = true
        setTimeout(() => {
          this.rateVisible = false
        }, 3500)
      }).catch(error => {
        console.error(error)
        this.isLoading = false
      })
    },

    // 判断是否是图片
    isImg (fileType) {
      return fileType.includes('image')
    },

    // 发送信息到后台存储
    send (attachment, textMsg, contentType) {
      const params = {
        ticketCaseId: this.ticketDetail.ticketCaseId,
        contentType
      }
      if (attachment) {
        params.attachment = attachment
      } else {
        params.content = textMsg
      }
      return this.$axios.post('/api/shipout-workorder/ticketcase/reply', params)
    },

    formatTextMsg (str) {
      return str
    },

    // 发送纯文本消息
    handleSendText (isEnter) {
      if (!this.isEnableSendBtn) return
      const contentType = CONTENT_TYPE.TEXT
      let _textMsg = this.textMsg
      if (isEnter) { // 按enter键发送
        _textMsg = _textMsg.replace(/\n$/g, '')
      }
      _textMsg = this.formatTextMsg(_textMsg)
      const tempRecord = {
        id: +new Date(),
        content: _textMsg,
        isLoading: true,
        isError: false,
        createTime: null,
        talkerType: 1, // 1客户 2客服
        userName: null,
        contentType
      }
      this.ticketChatRecords = this.ticketChatRecords.concat(tempRecord)
      this.mixinScrollTo(this.$refs['record-wrap'])
      this.textMsg = null
      this.send(null, _textMsg, contentType).then(r => {
        if (r.result === 'OK') {
          tempRecord.id = r.data.id
          tempRecord.isLoading = false
          tempRecord.isError = false
          tempRecord.createTime = this.$utils.toLocalTime(r.data.createTime)
          tempRecord.talkerType = r.data.talkerType
          tempRecord.userName = r.data.userName
        } else {
          tempRecord.isError = true
          tempRecord.isLoading = false
        }
      }).catch(error => {
        tempRecord.isError = true
        tempRecord.isLoading = false
        console.error(error)
      })
    },
    handleBlur () {
      this.canPasteUpload = false
    },
    handleFocus () {
      this.canPasteUpload = true
    },

    // 发送附件
    beforeUpload (files, uploadFunction) {
      files.forEach(file => {
        const contentType = this.isImg(file.type) ? CONTENT_TYPE.IMG : CONTENT_TYPE.OTHER
        const tempRecord = {
          fileName: file.name,
          fileUrl: window.URL.createObjectURL(file),
          id: file.lastModified,
          isLoading: true,
          isError: false,
          createTime: null,
          talkerType: 1, // 1客户 2客服
          userName: null,
          contentType
        }
        // 设置本地预览
        this.ticketChatRecords = this.ticketChatRecords.concat(tempRecord)
        this.mixinScrollTo(this.$refs['record-wrap'])
        // 开始上传
        uploadFunction(file).then(res => {
          const { fileId, url } = res.data
          const attachment = {
            fileId,
            fileName: file.name,
            fileUrl: url
          }
          this.send(attachment, null, contentType).then(r => {
            if (r.result === 'OK') {
              tempRecord.isLoading = false
              tempRecord.isError = false
              tempRecord.createTime = this.$utils.toLocalTime(r.data.createTime)
              tempRecord.talkerType = r.data.talkerType
              tempRecord.id = r.data.id
              tempRecord.userName = r.data.userName
              tempRecord.fileUrl = r.data.fileUrl
            } else {
              tempRecord.isError = true
              tempRecord.isLoading = false
            }
          }).catch(error => {
            tempRecord.isError = true
            tempRecord.isLoading = false
            console.error(error)
          })
        }).catch(error => {
          tempRecord.isError = true
          tempRecord.isLoading = false
          console.error(error)
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.c--detail-chat{
  flex: 1;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  .time{
    text-align: center;
    padding: 12px 0;
    flex: 0;
    color: $font-notice-color;
  }
  .record-wrap{
    flex: 1;
    overflow-y: auto;
    height: 1px;
    padding: 0 20px;
    .system-msg-item{
      text-align: center;
      padding: 20px 0;
      color: $font-notice-color;
      /deep/ .action-name{
        color: $font-second-color;
      }
    }
    .chat-msg-item{
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;
      /deep/ .ez-loading-spinner{
        margin-top: initial;
        transform: translateY(-50%);
        .ez-icon-loading{
          font-size: 22px;
          color: #ccc;
        }
      }
      .avatar{
        margin-right: 12px;
        order: 1;
        /deep/ svg{
          width: 38px!important;
          height: 38px!important;
        }
      }
      .content-container{
        flex: 1;
        order: 2;
        .message-time{
          color: $font-notice-color;
          margin-bottom: 6px;
        }
        .message-wrap{
          background: #F2F3F4;
          padding: 12px;
          border-radius: 4px;
          display: inline-block;
          position: relative;
          max-width: 400px;
          text-align: left;
          .text-wrap{
            text-align: left;
            word-break: keep-all;
            word-wrap: break-word;
            white-space: pre-wrap;
          }
          .msg-status{
            position: absolute;
            left: -30px;
            top: 50%;
            transform: translateY(-50%);
            .ez-icon-loading{
              font-size: 18px;
            }
            .ez-icon-warning{
              color: #F56C6C;
              font-size: 18px;
            }
          }
        }
      }
      &.is-right{
        .avatar{
          order: 3;
          margin-right: 0;
          margin-left: 12px;
        }
        .content-container{
          text-align: right;
          .message-wrap{
            background: rgba(51, 122, 244, 0.4);
            color: #333;
          }
        }
      }
      &.no-bg{
        .message-wrap{
          background: #fff!important;
        }
      }
    }
  }
  .send{
    display: flex;
    flex: 0;
    align-items: flex-end;
    padding: 10px 20px 0 20px;
    .input-wrap{
      flex: 1;
    }
    .send-btn{
      display: flex;
      align-items: center;
      .attach-icon{
        color: $color-primary;
        cursor: pointer;
        margin-right: 10px;
        /deep/ i{
          font-size: 24px;
        }
      }
      margin-left: 8px;
    }
  }
  .rate-wrap {
    margin: 20px;
    padding: 16px 22px;
    line-height: 22px;
    color: #333;
    border: 1px solid #E1E2E5;
    box-sizing: border-box;
    transition: all .3s;
    .rate-row {
      margin-bottom: 7px;
    }
    .rate-row--submitted {
      margin-top: 9px;
      margin-bottom: 3px;
      color: var(--color-info);
    }
    /deep/ .ez-radio-group {
      margin-left: 24px;
    }
    /deep/ .ez-radio {
      margin-left: 0;
      margin-right: 7px;
      border-radius: 4px;
      padding: 0 12px;
      border: 1px solid #C7CBD8;
      &:hover {
        background: #EAECF1;
      }
      &.is-checked {
        background: #C7CBD8;
      }
      .ez-radio__input {
        display: none;
      }
      .ez-radio__label {
        height: 28px;
        padding: 0;
        display: flex;
        align-items: center;
        i {
          margin-right: 7px;
          font-size: 18px;
        }
        & > span {
          padding-top: 2px;
        }
      }
    }
  }
}
</style>
