<template>
  <div class="c--file-type">
    <div
      v-for="item in renderList"
      :key="item.fileName"
      class="file-item"
    >
      <FileName :file-name="item.fileName" :file-url="getFileUrl(item.fileUrl, item.fileName)" />
    </div>
    <ez-button
      v-if="list.length > 1 && renderList.length <= 1"
      type="text"
      @click="handleShowMore"
    >
      Show {{ list.length - 1 }} more
    </ez-button>
  </div>
</template>

<script>
import { FILE_TYPES } from '@/config'
import { getFileUrl } from '@/utils/utils'
import mixin from '../mixin'
export default {
  mixins: [mixin],
  props: {
    list: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    list: {
      immediate: true,
      handler (val) {
        this.renderList = this.list.filter((item, index) => index === 0)
      }
    }
  },
  data () {
    return {
      FILE_TYPES,
      renderList: []
    }
  },
  computed: {
    icon () {
      return this.mixinGetFileIcon(1)
    }
  },
  methods: {
    handleShowMore () {
      this.renderList = [...this.list]
    },
    getFileUrl
  }
}
</script>

<style scoped lang="scss">
.c--file-type{
  .file-item{
    margin-bottom: 4px;
  }
}
</style>
