<template>
  <div class="ez-rate-star">
    <i
      v-for="(star, index) in stars"
      :key="index"
      :class="[
        'oms__iconfont',
        'oms__icon_start',
        'ez-rate-star__item',
        {
          'is-disabled': disabled,
          'is-selected': displayVal >= star
        }]"
      @mouseenter="onMouseenter(star)"
      @mouseleave="onMouseleave(star)"
      @click="onClick(star)"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      stars: new Array(5).fill(0).map((v, i) => i + 1),
      displayVal: this.value || 0
    }
  },
  watch: {
    value: {
      handler (val) {
        this.displayVal = val || 0
      }
    }
  },
  methods: {
    onMouseenter (star) {
      if (this.disabled) return

      this.displayVal = star
    },
    onMouseleave (star) {
      if (this.disabled) return

      this.displayVal = this.value
    },
    onClick (star) {
      if (this.disabled) return
      if (star === this.value) return

      this.$emit('input', star)
      this.$emit('change', star)
      this.displayVal = star
    }
  }
}
</script>
<style lang="scss">
.ez-rate-star {
  height: 20px;
  line-height: 20px;
  .ez-rate-star__item {
    margin-right: 5px;
    font-size: 20px;
    color: var(--color-info-light5);
    transition: all .3s;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:not(.is-disabled):not(.is-selected):hover {
      color: var(--color-warning-light5);
    }
    &.is-disabled {
      cursor: not-allowed;
    }
    &.is-selected {
      color: var(--color-warning);
    }
  }
}

</style>
