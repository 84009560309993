<template>
  <label>
    <input
      :accept="accept"
      type="file"
      style="display: none"
      multiple
      @change="handleSelectFile"
    >
    <i class="oms__iconfont oms__icon_Attachment" />
  </label>
</template>

<script>
import axios from 'axios'
const uploadAxios = axios.create()

export default {
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    maxNum: { // 最大多选数量
      type: Number,
      default: 9
    },
    maxSize: { // 每个文件最大尺寸，单位M
      type: Number,
      default: 100
    },
    accept: {
      type: String,
      default: 'image/*,.pdf,.xls, .xlsx, .csv'
    }
  },
  data () {
    return {
      qiniuInfo: {}
    }
  },
  methods: {
    // 选择文件
    handleSelectFile (e) {
      let files = Array.from(e.target.files)
      let exceedMaxSizeFiles = files.filter(item => item.size > this.maxSize * 1024 * 1024)
      if (exceedMaxSizeFiles.length) return this.onExceedSize(files, exceedMaxSizeFiles, this.maxSize)
      if (files.length > this.maxNum) return this.onExceedNum(files, this.maxNum)
      this.$emit('beforeUpload', files, this.uploadFile)
      e.target.value = null
    },

    // 当选择的文件中有尺寸大于最大允许尺寸时
    onExceedSize (files, exceedMaxSizeFiles, maxSize) {
      if (this.$listeners.onExceedSize) return this.$emit('onExceedSize', files, exceedMaxSizeFiles, maxSize)
      return this.$message.warning(`The maximum file size is ${maxSize}M`)
    },

    // 当文件数量大于最大允许文件数量时
    onExceedNum (files, maxNum) {
      if (this.$listeners.onExceedNum) return this.$emit('onExceedNum', files, maxNum)
      return this.$message.warning(`A maximum of ${maxNum} files can be selected at a time`)
    },

    // 上传文件
    uploadFile (file) {
      return this.$axios.get('/api/wms-platform/qny/getUploadInfo', {
        params: { applicationType: 'ticket' }
      })
        .then(res => res.data)
        .then(({ token, key, action }) => {
          let formData = new FormData()
          let params = {
            file,
            token,
            key,
            action
          }
          Object.keys(params).forEach(key => formData.append(key, params[key]))
          return new Promise((resolve, reject) => {
            uploadAxios({
              method: 'post',
              url: action,
              headers: { 'Content-Type': 'multipart/form-data' },
              data: formData
            }).then(res => {
              resolve(res.data)
            }).catch(err => {
              reject(err)
            })
          })
        })
    }
  }
}
</script>
