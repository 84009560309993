<template>
  <div class="c--detail-preview">
    <div class="status">
      <StatusTag :status="ticketDetail.clientStatus" />
      <span class="case-no">Case# {{ ticketDetail.caseNo }}</span>
    </div>
    <div class="desc">
      <p class="topic">
        {{ ticketDetail.subject }}
      </p>
    </div>
    <div class="content">
      <ez-form label-width="120px">
        <ez-form-item :label="$t('common.ticketType') + ': '">
          {{ ticketDetail.ticketTypeEn }}
        </ez-form-item>
        <ez-form-item
          v-for="(item, index) in configureFieldContent"
          :key="index"
          :label="getLabel(item)"
        >
          {{ formatValue(item.value) }}
        </ez-form-item>
        <ez-form-item :label="$t('common.description') + ': '">
          {{ ticketDetail.description }}
        </ez-form-item>
        <ez-form-item :label="$t('common.createTime') + ': '">
          {{ ticketDetail.createTime }}
        </ez-form-item>
        <ez-form-item :label="$t('common.attachment') + ': '" v-if="ticketDetail.attachmentList && ticketDetail.attachmentList.length">
          <Attachments :list="ticketDetail.attachmentList" />
        </ez-form-item>
      </ez-form>
    </div>
  </div>
</template>

<script>
import StatusTag from '../components/StatusTag'
import Attachments from '../components/Attachments'
export default {
  components: { StatusTag, Attachments },
  props: {
    ticketDetail: {
      type: Object,
      default () {
        return {
        }
      }
    }
  },
  data () {
    return {
      isRateChangeLoading: false
    }
  },
  computed: {
    configureFieldContent () {
      const t = this.ticketDetail.configureFieldContent
      return t ? JSON.parse(t) : t
    }
  },
  methods: {
    getLabel (item) {
      return {
        en: item.labelEn,
        cn: item.labelCn
      }[this.$utils.getUserInfo().language] + ': '
    },
    formatValue (value) {
      return Array.isArray(value) ? value.join(', ') : value
    },
    handleRateChange (rate) {
      this.isRateChangeLoading = true
      this.$axios.post('/api/shipout-workorder/admin/ticketCase/markPriority', {
        priority: rate,
        ticketCaseId: this.ticketDetail.ticketCaseId
      }).then(() => {
        this.isRateChangeLoading = false
        this.$emit('refreshDetail')
      }).catch(error => {
        console.error(error)
        this.isRateChangeLoading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.c--detail-preview{
  flex: 0.4;
  border-right: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  min-width: 310px;
  /deep/ .ez-form-item__content{
    word-break: break-all;
  }
  /deep/ .ez-form-item__content{
    line-height: normal;
  }
  /deep/ .ez-form-item__label{
    line-height: normal;
  }
  .status{
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-color;
    color: $font-notice-color;
  }
  .desc{
    padding: 20px 0;
    margin: 0 12px;
    border-bottom: 1px solid $border-color;
    .topic{
      color: #000;
      font-size: $font-first-size;
      line-height: 24px;
      margin-bottom: 7px;
      .oms__icon_gongdan{
        margin-right: 6px;
        font-size: 16px;
      }
    }
    .email{
      line-height: 22px;
    }
  }
  .content{
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 12px;
  }
}
</style>
