var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ez-rate-star"},_vm._l((_vm.stars),function(star,index){return _c('i',{key:index,class:[
      'oms__iconfont',
      'oms__icon_start',
      'ez-rate-star__item',
      {
        'is-disabled': _vm.disabled,
        'is-selected': _vm.displayVal >= star
      }],on:{"mouseenter":function($event){return _vm.onMouseenter(star)},"mouseleave":function($event){return _vm.onMouseleave(star)},"click":function($event){return _vm.onClick(star)}}})}),0)}
var staticRenderFns = []

export { render, staticRenderFns }