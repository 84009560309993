<template>
  <div class="p--ticket-detail">
    <!--    操作-->
    <section class="detail-actions">
      <ActionsGroup
        :ticket-detail="mixinTicketDetail"
        :scene="SCENES.DETAIL"
        :call-back="getTicketDetail"
      />
    </section>

    <!--    详情-->
    <section class="block-container">

      <div class="detail-wrap">
        <!--左侧概览-->
        <TicketDetailPreview
          v-loading="detailBlockLoading"
          :ticket-detail="mixinTicketDetail"
          @refreshDetail="getTicketDetail"
        />

        <!--      中间聊天部分-->
        <TicketDetailChat ref="chat" :ticket-detail="mixinTicketDetail" />
      </div>

    </section>

    <!--    关闭-->
    <DialogClose
      v-if="mixinIsShowDialogConfirm"
      :confirmType="mixinCurConfirmType"
      :ticket-case-ids="[mixinTicketDetail.ticketCaseId]"
      :success-call-back="getTicketDetail"
      @close="mixinIsShowDialogConfirm = false"
    />
  </div>
</template>

<script>
import TicketDetailPreview from './TicketDetailPreview'
import TicketDetailChat from './TicketDetailChat'
import mixin from '../mixin'
import ActionsGroup, { SCENES } from '@/views/components/ActionsGroup'
export default {
  components: {
    TicketDetailPreview,
    TicketDetailChat,
    ActionsGroup
  },
  mixins: [mixin],
  data () {
    return {
      SCENES,
      detailBlockLoading: false
    }
  },
  mounted () {
    this.getTicketDetail()
  },
  methods: {
    getTicketDetail () { // 获取左侧概览数据
      const params = {
        ticketCaseId: this.$route.params.ticketCaseId
      }
      this.detailBlockLoading = true
      this.$axios.get('/api/shipout-workorder/ticketcase/getDetailById', { params })
        .then(res => {
          this.mixinTicketDetail = {
            ...res.data,
            createTime: this.$utils.toLocalTime(res.data.createTime),
            updateTime: this.$utils.toLocalTime(res.data.updateTime)
          }
          this.detailBlockLoading = false
          this.$store.commit('setDetailCaseNo', res.data.caseNo)
          this.$refs.chat.getChatRecords()
        })
        .catch(error => {
          console.error(error)
          this.detailBlockLoading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.p--ticket-detail{
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: $font-second-size;
  .detail-actions{
    padding: 0 16px 0 16px;
    width: 1190px;
    margin: 24px auto 6px;
  }
  .block-container{
    height: 1px;
    flex: 1;
    padding: 0 16px 57px 16px;
    width: 1190px;
    margin: 0 auto;
    .detail-wrap{
      display: flex;
      height: 100%;
      border: 1px solid $border-color;
      .detail-middle{
        flex: 1;
        border-right: 1px solid $border-color;
        padding: 12px 0;
        display: flex;
        flex-direction: column;
        .time{
          text-align: center;
          padding: 12px 0;
          flex: 0;
        }
        .record-wrap{
          flex: 1;
          overflow-y: auto;
          height: 1px;
          padding: 0 20px;
          .record-item{
            display: flex;
            align-items: flex-start;
            margin-bottom: 30px;
            /deep/ .ez-loading-spinner{
              margin-top: initial;
              transform: translateY(-50%);
              .ez-icon-loading{
                font-size: 22px;
                color: #ccc;
              }
            }
            .avatar{
              margin-right: 12px;
              order: 1;
            }
            .content-container{
              flex: 1;
              order: 2;
              .message-time{
                color: $font-notice-color;
                margin-bottom: 6px;
              }
              .message-wrap{
                background: #F2F3F4;
                padding: 12px;
                border-radius: 4px;
                display: inline-block;
              }
            }
            &.is-main{
              .avatar{
                order: 3;
                margin-right: 0;
                margin-left: 12px;
              }
              .content-container{
                text-align: right;
                .message-wrap{
                  background: rgba(51, 122, 244, 0.4);
                  color: #333;
                }
              }
            }
          }
        }
        .send{
          display: flex;
          flex: 0;
          align-items: center;
          padding: 10px 20px 0 20px;
          .input-wrap{
            position: relative;
            flex: 1;
            .attach-icon{
              position: absolute;
              top: 2px;
              right: 10px;
              color: $color-primary;
              cursor: pointer;
              /deep/ i{
                font-size: 24px;
              }
            }
          }
          .send-btn{
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
