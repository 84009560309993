<template>
  <div />
</template>

<script>
import axios from 'axios'
const uploadAxios = axios.create()

export default {
  name: 'EzPasteUpload',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    document.addEventListener('paste', this.handlePaste, true)
  },
  beforeDestroy () {
    document.removeEventListener('paste', this.handlePaste, true)
  },
  methods: {
    async handlePaste (event) {
      if (this.disabled) return
      const files = []
      const items = (event.clipboardData || event.originalEvent.clipboardData).items
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (item.type.indexOf('image') !== -1) {
          const file = item.getAsFile()
          files.push(file)
        }
      }

      if (files.length) {
        this.$emit('beforeUpload', files, this.uploadFile)
        // event.target.value = null
      }
    },
    // 上传文件
    uploadFile (file) {
      return this.$axios.get('/api/wms-platform/qny/getUploadInfo', {
        params: { applicationType: 'ticket' }
      })
        .then(res => res.data)
        .then(({ token, key, action }) => {
          let formData = new FormData()
          let params = {
            file,
            token,
            key,
            action
          }
          Object.keys(params).forEach(key => formData.append(key, params[key]))
          return new Promise((resolve, reject) => {
            uploadAxios({
              method: 'post',
              url: action,
              headers: { 'Content-Type': 'multipart/form-data' },
              data: formData
            }).then(res => {
              resolve(res.data)
            }).catch(err => {
              reject(err)
            })
          })
        })
    }
  }
}
</script>
